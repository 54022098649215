<template>
  <el-dialog
    append-to-body
    :destroy-on-close="true"
    :close-on-click-modal="false"
    title="分配供货节点"
    :visible.sync="show"
    width="550px"
  >
    <el-tree
      v-loading="loading"
      :data="treeData"
      show-checkbox
      ref="tree"
      :default-expanded-keys="[id]"
      node-key="id"
    >
    </el-tree>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">取消</el-button>
      <el-button type="primary" @click="confirmHandler" :loading="btnLoading"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
import { id } from '@/treeId.js'
export default {
  mixins: [dialogCommonParams],
  props: ['supplierId', 'middlemanId'],
  data() {
    return {
      treeData: [
        {
          id: id,
          label: '省行',
          children: [
            // {
            //   id: '1-1',
            //   label: '省行',
            //   children: []
            // }
          ]
        }
      ],
      id,
      loading: false,
      btnLoading: false
    }
  },
  async created() {
    this.getAllNodeData()
  },
  methods: {
    async getAllNodeData() {
      try {
        this.loading = true
        const res = await this.$api.supplier.listSupplyNode()

        this.treeData[0].children = (res.allianceVoList || []).map(item => ({
          id: item.id,
          label: item.businessName,
          children: item.merchantVoList.map(_ => ({
            id: _.id,
            label: _.merchantName
          }))
        }))

        this.getCurrentNode()
      } catch (e) {
        console.log(e)
      }
    },

    async getCurrentNode() {
      try {
        let sendData = {}

        if (this.supplierId) {
          sendData.supplierId = this.supplierId
        } else {
          sendData.middlemanId = this.middlemanId
        }

        if (localStorage.getItem('userType') == 'SUPPLIER') {
          sendData.type = 2
        } else {
          sendData.type = 1
        }

        const res = await this.$api.supplier.getCurrentNode(sendData)

        this.$refs.tree.setCheckedKeys(
          (res.supplyNodeList || []).map(item => item.nodeId)
        )
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async confirmHandler() {
      const checkedNodes = this.$refs.tree.getCheckedNodes()

      const arr = []
      const arrIds = []

      checkedNodes.forEach(item => {
        if (item.children && item.children.length) {
          item.children.forEach(_ => {
            arrIds.push(_.id)
          })
        }
      })

      const needData = checkedNodes.filter(item => !arrIds.includes(item.id))

      needData.forEach(item => {
        arr.push({
          nodeId: item.id,
          nodeName: item.label,
          nodeType: item.label == '省行' ? 1 : item.children ? 2 : 3
        })
      })

      let sendData = {
        supplyNodeList: arr
      }

      if (this.supplierId) {
        sendData.supplierId = this.supplierId
      } else {
        sendData.middlemanId = this.middlemanId
      }

      if (localStorage.getItem('userType') == 'SUPPLIER') {
        sendData.type = 2
      } else {
        sendData.type = 1
      }

      try {
        this.loading = true
        await this.$api.supplier.updateNode(sendData)

        this.$message.success('保存成功')
        this.show = false
        this.$emit('getData')
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
