<template>
  <el-dialog
    class="supplierModal"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    title="关联中间商"
    :visible.sync="show"
    width="1200px"
  >
    <div class="formWrap">
      <el-form inline :model="params" ref="filtersForm">
        <el-form-item label="中间商名称" prop="name">
          <el-input placeholder="中间商名称" v-model="params.name" />
        </el-form-item>
        <el-form-item label="联系人" prop="linkMan">
          <el-input placeholder="联系人" v-model="params.linkMan" />
        </el-form-item>
        <el-form-item label="联系方式" prop="linkPhone">
          <el-input placeholder="联系方式" v-model="params.linkPhone" />
        </el-form-item>
        <el-button type="primary" plain @click="getData(true)">
          查询
        </el-button>
        <el-button plain @click="parentClear">重置</el-button>
      </el-form>

      <el-button type="primary" @click="relateSupplier">关联中间商</el-button>
    </div>

    <div>
      <el-table :data="tableData" v-loading="loading" :height="400" border>
        <el-table-column
          show-overflow-tooltip
          prop="name"
          label="中间商名称"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="linkMan"
          label="联系人"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="linkPhone"
          label="联系方式"
        ></el-table-column>
        <el-table-column
          prop="supplyAddress"
          label="供货范围"
        ></el-table-column>
        <el-table-column width="200" label="操作">
          <template slot-scope="{ row }">
            <!-- <el-button @click="distributeHandler(row)" size="mini" type="text">
              管理供货范围</el-button
            > -->
            <el-button
              @click="unrelate(row.relationId)"
              size="mini"
              type="text"
            >
              解除关联</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <pagination
        slot="pagination"
        :totalCount="totalCount"
        :page-size.sync="params.limit"
        :page.sync="params.page"
        @change="getData"
      />
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">取消</el-button>
    </span>

    <RelateSupplierModal
      v-if="relateSupplierVisible"
      @getData="getData(true)"
      :visible.sync="relateSupplierVisible"
      :supplierId="supplierId"
    />

    <DistributeModal
      @getData="getData(true)"
      v-if="distributeVisible"
      :visible.sync="distributeVisible"
      :middlemanId="middlemanId"
    />
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
import RelateSupplierModal from './RelateSupplierModal'
import DistributeModal from './DistributeModal'

export default {
  mixins: [dialogCommonParams],
  data() {
    return {
      supplierId: null,
      middlemanId: null,
      loading: false,
      relateSupplierVisible: false,
      distributeVisible: false,
      tableData: [],
      totalCount: 0,
      params: {
        limit: 20,
        page: 1
      }
    }
  },
  components: {
    RelateSupplierModal,
    DistributeModal
  },
  created() {
    this.getData()
  },
  methods: {
    parentClear() {
      const filtersForm = this.$refs.filtersForm
      filtersForm && filtersForm.resetFields()
      this.getData(true)
    },
    relateSupplier() {
      this.supplierId = this.currentItem.id
      this.relateSupplierVisible = true
    },
    distributeHandler(row) {
      this.middlemanId = row.id
      this.distributeVisible = true
    },
    unrelate(id) {
      this.$confirm(`是否解除该中间商与供应商的关系`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        try {
          await this.$api.supplier.unrelate(id)
          this.$message.success('解除成功！')
          this.getData(true)
        } catch (e) {
          console.log(e)
        } finally {
        }
      })
    },
    async getData(query) {
      this.loading = true

      if (query) {
        this.params.limit = 20
        this.params.page = 1
      }

      const sendData = { ...this.params }

      sendData.supplierId = this.currentItem.id

      try {
        const r = await this.$api.supplier.getRelateSupplierList(sendData)
        this.tableData = ((r.page && r.page.list) || []).map(item => {
          item.supplyAddress = (item.nodeVoList || [])
            .map(_ => _.nodeName)
            .join('；')

          return item
        })

        this.totalCount = (r.page && r.page.totalCount) || 0
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style lang="scss">
.supplierModal .el-dialog__body {
  padding: 0 20px !important;
}

.supplierModal .el-dialog {
  margin-top: 5vh !important;
}

.supplierModal .el-dialog .el-form-item {
  margin-bottom: 5px !important;
}

.pagination-wrap {
  justify-content: center !important;
}

.formWrap {
  display: flex;
  justify-content: space-between;
  height: 30px;
  margin-bottom: 10px;
}
</style>
