<template>
  <page v-loading="loading">
    <template slot="headerLeft">
      <el-form inline :model="params" ref="filtersForm">
        <el-form-item label="供应商" prop="name">
          <el-input v-model="params.name" placeholder="供应商" clearable />
        </el-form-item>

        <el-form-item label="联系人" prop="linkMan">
          <el-input v-model="params.linkMan" placeholder="联系人" clearable />
        </el-form-item>

        <el-form-item label="联系人电话" prop="linkPhone">
          <el-input
            v-model="params.linkPhone"
            placeholder="联系人电话"
            clearable
          />
        </el-form-item>

        <el-form-item label="供应商来源" prop="resource">
          <el-select v-model="params.resource" clearable>
            <el-option
              v-for="item in resourceList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </template>

    <template slot="headerRight">
      <el-button v-auth="'supplier:add'" type="primary" @click="addSupplier"
        >添加供应商</el-button
      >
    </template>

    <div class="table-wrap">
      <el-table :data="tableData" border height="100%" style="width: 100%">
        <el-table-column
          show-overflow-tooltip
          prop="id"
          min-width="140"
          label="供应商ID"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="name"
          min-width="100"
          label="供应商名称"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="linkMan"
          label="联系人"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="linkPhone"
          label="联系电话"
        ></el-table-column>
        <el-table-column show-overflow-tooltip prop="resource" label="来源">
          <template slot-scope="{ row }">
            <span>{{ row.source == 1 ? '自主创建' : '外部系统' }}</span>
          </template>
        </el-table-column>

        <el-table-column
          min-width="100"
          show-overflow-tooltip
          label="是否为服务商"
        >
          <template slot-scope="{ row }">
            <span>{{ row.isServiceProvider == 0 ? '否' : '是' }}</span>
          </template>
        </el-table-column>

        <!-- <el-table-column show-overflow-tooltip label="所属区域">
          <template slot-scope="{ row }">
            <span>{{ row.address || '-' }}</span>
          </template>
        </el-table-column> -->
        <!-- <el-table-column
          width="120"
          show-overflow-tooltip
          prop="subMchId"
          label="子商户清分编号"
        ></el-table-column> -->
        <el-table-column show-overflow-tooltip label="状态">
          <template slot-scope="{ row }">
            <span :style="{ color: row.status == 1 ? 'green' : 'red' }">{{
              row.status == 1 ? '启用' : '停用'
            }}</span>
          </template>
        </el-table-column>
        <el-table-column width="350" label="操作">
          <template slot-scope="{ row }">
            <el-button
              v-auth="'supplier:update'"
              type="text"
              @click="editHandler(row)"
              >编辑</el-button
            >
            <el-button
              v-auth="'supplier:distribute'"
              type="text"
              @click="distribution(row)"
              >分配供货节点</el-button
            >
            <el-button
              v-auth="'supplier:relation'"
              type="text"
              @click="relateMiddleMan(row)"
              >关联中间商</el-button
            >
            <el-button
              v-auth="'supplier:reset'"
              type="text"
              @click="resetPassword(row.linkPhone)"
              >重置密码</el-button
            >
            <el-button
              v-auth="row.status == 1 ? 'supplier:stop' : 'supplier:open'"
              @click="operate(row)"
              type="text"
              >{{ row.status == 1 ? '停用' : '启用' }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <pagination
      slot="pagination"
      :totalCount="totalCount"
      :page-size.sync="params.limit"
      :page.sync="params.page"
      @change="getData"
    />

    <AddModal
      @getData="getData(true)"
      v-if="addVisible"
      :visible.sync="addVisible"
      :currentItem="currentItem"
    />

    <DistributeModal
      @getData="getData(true)"
      v-if="distributeVisible"
      :visible.sync="distributeVisible"
      :supplierId="supplierId"
    />

    <SupplierModal
      v-if="supplierVisible"
      :visible.sync="supplierVisible"
      :currentItem="currentItem"
    />
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
import AddModal from './components/AddModal'
import DistributeModal from './components/DistributeModal'
import SupplierModal from './components/SupplierModal'
export default {
  mixins: [watchParamsGetData],
  data() {
    return {
      loading: false,
      supplierId: null,
      currentItem: null,
      addVisible: false,
      distributeVisible: false,
      supplierVisible: false,
      tableData: [],
      resourceList: [
        {
          label: '自主创建',
          value: 1
        },
        {
          label: '外部系统',
          value: 2
        }
      ],
      params: {
        index: 0,
        limit: 20,
        page: 1
      },
      totalCount: 0
    }
  },
  components: {
    AddModal,
    DistributeModal,
    SupplierModal
  },
  created() {
    // this.getResourceList()
    this.getData()
  },
  methods: {
    addSupplier() {
      this.currentItem = null
      this.addVisible = true
    },
    distribution(row) {
      this.supplierId = row.id
      this.distributeVisible = true
    },
    relateMiddleMan(row) {
      this.currentItem = row
      this.supplierVisible = true
    },
    editHandler(row) {
      this.currentItem = row
      this.addVisible = true
    },
    async resetPassword(mobile) {
      try {
        await this.$confirm(
          `默认密码是12345678,请确认是否要重置密码?`,
          '提示',
          {
            type: 'warning',
            confirmButtonText: '确认',
            cancelButtonText: '取消'
          }
        )

        try {
          this.loading = true

          await this.$api.supplier.resetPassword({ mobile })
          this.$message.success('操作成功！')
          this.getData(true)
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      } catch (err) {}
    },
    async getResourceList() {
      try {
        const res = await this.$api.common.getResourceList()

        this.resourceList = (res.brands || []).map(item => {
          return {
            label: item.name,
            value: item.id
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    async operate(row) {
      try {
        await this.$confirm(
          `是否要${row.status == 1 ? '停用' : '启用'}?`,
          '提示',
          {
            type: 'warning',
            confirmButtonText: '确认',
            cancelButtonText: '取消'
          }
        )

        try {
          this.loading = true

          await this.$api.supplier.supplierUpdate({
            id: row.id,
            isServiceProvider: row.isServiceProvider,
            status: row.status == 1 ? 2 : 1
          })

          this.$message.success('操作成功！')
          this.getData(true)
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      } catch (err) {}
    },
    async getData(query) {
      this.loading = true

      const sendData = JSON.parse(JSON.stringify(this.params))

      if (query) {
        sendData.page = 1
        sendData.limit = 20
      }

      try {
        const res = await this.$api.supplier.getList(sendData)
        this.cartEntity = res.cartEntity || {}

        const tableData = res.page.list.map(item => {
          const target = JSON.parse(item.payInfo || '{}')
          item.subMchId = target.sub_mch_id || ''
          item.status = item.status || 0

          return item
        })

        this.tableData = tableData
        this.totalCount = res.page.totalCount
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss"></style>
